<template>
  <div class="col-12 col-md-10 col-xl-9 mb-3">
    <div class="row" v-if="isNewBankValue">
      <div class="col-12 text-center mb-3">
        <Button color="gold" btnText="Use Existing Bank Account" @buttonClicked="useExistingBank" />
      </div>
    </div>
    <div class="row">
      <div class="col-md-6 mb-3">
        <SelectAll
          v-model="selectedBank"
          id="bank"
          :options="bankOptions"
          placeholder="Select Bank"
          displayValue="bankName"
          color="gold"
          :hasMore="true"
        />
      </div>
      <div class="col-md-6 mb-3">
        <TextField type="text" color="gold" placeholder="Account Holder Name" v-model="bankAccountDetailsValue.accountHolderName" id="name" />
      </div>
      <div class="col-md-6 mb-3">
        <TextField type="text" color="gold" placeholder="Branch Code" v-model="bankAccountDetailsValue.branchCode" id="branch" />
      </div>
      <div class="col-md-6 mb-3">
        <TextField type="text" color="gold" placeholder="Account Number" v-model="bankAccountDetailsValue.accountNumber" id="number" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('@/components/Button.vue')),
    SelectAll: defineAsyncComponent(() => import('@/components/SelectAll.vue')),
    TextField: defineAsyncComponent(() => import('@/components/TextField.vue'))
  },
  props: ['isNewBank', 'bankAccountDetails'],
  emits: ['update:bankAccountDetails', 'update:isNewBank'],
  data () {
    return {
      selectedBank: {}
    }
  },
  async beforeMount () {
    if (!this.bankOptions || this.bankOptions.length === 0) {
      await this.fetchBankOptions()
    }
    await this.setBankFromName()
  },
  watch: {
    selectedBank: {
      deep: true,
      handler (val) {
        this.setBankNameFromObject(this.selectedBank)
      }
    }
  },
  computed: {
    ...mapGetters([
      'bankOptions'
    ]),
    bankAccountDetailsValue: {
      get () {
        return this.bankAccountDetails
      },
      set (val) {
        this.$emit('update:bankAccountDetails', val)
      }
    },
    isNewBankValue: {
      get () {
        return this.isNewBank
      },
      set (val) {
        this.$emit('update:isNewBank', val)
      }
    }
  },
  methods: {
    ...mapActions(['fetchBankOptions']),
    useExistingBank () {
      this.isNewBankValue = false
      this.selectedBank = {}
      this.bankAccountDetailsValue = {
        bankName: null,
        accountHolderName: null,
        accountNumber: null,
        reference: null,
        branchCode: null
      }
    },
    setBankFromName () {
      if (this.bankOptions && this.bankOptions.length > 0 && this.bankAccountDetailsValue && this.bankAccountDetailsValue.bankName) {
        const bank = this.bankOptions.find(bank => this.bankAccountDetailsValue.bankName === bank.bankName)
        this.selectedBank = { ...bank }
      }
    },
    setBankNameFromObject () {
      if (this.selectedBank && this.selectedBank.bankName) {
        this.bankAccountDetailsValue.bankName = this.selectedBank.bankName
      }
    }
  }
}
</script>
